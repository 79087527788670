import React from 'react'

interface PaginatorProps {
  currentPage: number
  totalPages: number
  handlePageChange: (page: number) => void
}

const Paginator: React.FC<PaginatorProps> = ({
  currentPage,
  totalPages,
  handlePageChange,
}) => {
  const getPageNumbers = () => {
    const pages = []
    const isNearEnd = currentPage >= totalPages - 2
    const isNearStart = currentPage <= 3

    if (window.innerWidth < 768) {
      if (isNearStart) {
        pages.push(1, currentPage, '...', totalPages)
      } else if (isNearEnd) {
        pages.push(1, '...', currentPage, totalPages)
      } else {
        pages.push(1, '...', currentPage, '...', totalPages)
      }
    } else {
      if (isNearStart) {
        pages.push(1, 2)
        if (currentPage > 2) pages.push(currentPage)
        pages.push('...', totalPages - 1, totalPages)
      } else if (isNearEnd) {
        pages.push(1, 2, '...')
        for (let i = totalPages - 2; i <= totalPages; i++) {
          if (i === currentPage) {
            pages.push(i)
          } else if (!pages.includes(i)) {
            pages.push(i)
          }
        }
      } else {
        pages.push(1, 2, '...')
        pages.push(currentPage)
        pages.push('...', totalPages - 1, totalPages)
      }
    }

    return pages
  }

  const pageNumbers = getPageNumbers()

  const onHandlePageChange = (
    e: React.MouseEvent<HTMLButtonElement>,
    value: number,
  ) => {
    e.preventDefault()
    handlePageChange(value)
  }

  return (
    <div className="flex flex-wrap items-center justify-center gap-2 sm:gap-4">
      <button
        className="inline-flex items-center rounded-md bg-gray-100 px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-200 disabled:opacity-50"
        onClick={e => onHandlePageChange(e, currentPage - 1)}
        disabled={currentPage === 1}>
        Prev
      </button>

      <div className="flex flex-wrap items-center justify-center gap-2 sm:gap-3">
        {pageNumbers.map((page, index) =>
          typeof page === 'number' ? (
            <button
              key={index}
              onClick={e => onHandlePageChange(e, page)}
              className={`px-3 py-2 text-sm font-medium ${
                currentPage === page
                  ? 'rounded-md bg-indigo-600 text-white'
                  : 'rounded-md bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}>
              {page}
            </button>
          ) : (
            <span
              key={index}
              className="px-2 py-2 text-sm font-medium text-gray-500">
              ...
            </span>
          ),
        )}
      </div>

      <button
        className="inline-flex items-center rounded-md bg-gray-100 px-3 py-2 text-sm font-medium text-gray-500 hover:bg-gray-200 disabled:opacity-50"
        onClick={e => onHandlePageChange(e, currentPage + 1)}
        disabled={currentPage >= totalPages}>
        Next
      </button>
    </div>
  )
}

export default Paginator
