import {
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
  HandThumbDownIcon,
  FunnelIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline'
import {useSearchParams} from 'react-router-dom'
import * as React from 'react'

import BooleanFilterControl from '../components/BooleanFilterControl'
import ArrayFilterControl from '../components/ArrayFilterControl'
import useSearchResults from '../hooks/useSearch'
import {classNames} from '../utils'
import FilterChips from '../components/FilterChips'
import * as types from '../types'
import ListItem from '../components/ListItem'
import Spinner from '../components/Spinner'
import useItemTypes from '../hooks/useItemTypes'
import Paginator from '../components/Paginator'

type State = types.SearchParams & {
  page: number
  itemsPerPage: number
}

type Action =
  | {type: 'sort changed'; payload: State['sort']}
  | {type: 'query changed'; payload: State['q']}
  | {type: 'page changed'; payload: State['page']}
  | {
      type: 'filter changed'
      payload: {
        group: keyof State['filters']
        value: string
        isChecked: boolean
      }
    }
  | {type: 'filter group cleared'; payload: {group: keyof State['filters']}}
  | {type: 'all cleared'}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'query changed': {
      return {...state, q: action.payload}
    }

    case 'page changed': {
      return {...state, page: action.payload}
    }

    case 'sort changed': {
      return {...state, sort: action.payload}
    }

    case 'filter changed': {
      const currentValues = state.filters[action.payload.group]
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.group]: action.payload.isChecked
            ? [...currentValues, action.payload.value]
            : currentValues.filter(
                v =>
                  v !== action.payload.value &&
                  v.split('|')[0] !== action.payload.value,
              ),
        },
      }
    }

    case 'filter group cleared': {
      return {
        ...state,
        filters: {...state.filters, [action.payload.group]: []},
      }
    }

    case 'all cleared': {
      return init()
    }
  }
}

function init(currentParams?: URLSearchParams): State {
  const params = currentParams || new URLSearchParams()
  return {
    q: params.get('q') || '',
    sort: params.get('sort') || 'edition|desc',
    page: parseInt(params.get('page') || '1', 10),
    itemsPerPage: parseInt(params.get('limit') || '10', 10),
    filters: {
      isRecommended: params.getAll('isRecommended'),
      type: params.getAll('type'),
      certificate: params.getAll('certificate'),
      curriculum: params.getAll('curriculum'),
      component: params.getAll('component'),
      genre: params.getAll('genre'),
      format: params.getAll('format'),
      cycle: params.getAll('cycle'),
      language: params.getAll('language'),
      approach: params.getAll('approach'),
      workload: params.getAll('workload'),
      subject: params.getAll('subject'),
      serie: params.getAll('serie'),
      level: params.getAll('level'),
    },
  } as State
}

export default function SearchPage() {
  const [searchParams, setSearchParams] = useSearchParams()

  const [state, dispatch] = React.useReducer(reducer, searchParams, init)
  const [filterByModal, setFilterByModal] = React.useState(false)
  const [activeTypes, setActiveTypes] = React.useState<
    ('Text' | 'Literature')[]
  >([])
  const [isPageChanged, setIsPageChanged] = React.useState(false)
  const [matchedTypes, setMatchedTypes] = React.useState<types.ItemType[]>([])
  const [uniquePropNames, setUniquePropNames] = React.useState<string[]>([])
  const [totalPages, setTotalPages] = React.useState<number>(0)
  const {data: itemTypes} = useItemTypes()

  // update the URL querystring each time the search state changes
  React.useEffect(() => {
    const {filters, page, itemsPerPage, ...other} = state
    const offset = isPageChanged
      ? (page - 1) * itemsPerPage
      : searchParams.get('offset')

    setSearchParams(
      {
        ...filters,
        ...other,
        limit: itemsPerPage.toString(),
        offset: offset ? offset.toString() : '0',
        page: page.toString(),
      },
      {replace: true},
    )
    setActiveTypes(filters.type)
  }, [setSearchParams, state, isPageChanged, searchParams])

  const {data, isValidating, error} = useSearchResults(state, {
    // handy for keep displaying the filters while executing a new search
    keepPreviousData: true,
  })
  React.useEffect(() => {
    setTotalPages(data ? Math.ceil(data.count / state.itemsPerPage) : 1)
  }, [data, state.itemsPerPage])

  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > totalPages) return
    dispatch({type: 'page changed', payload: newPage})
    setIsPageChanged(true)
  }

  const isSearching = !data && !error

  const handleToggleFilter = (
    group: keyof State['filters'],
    value: string,
    isChecked: boolean,
  ) => {
    dispatch({type: 'filter changed', payload: {group, value, isChecked}})
  }

  const handleClearGroup = (group: keyof State['filters']) => {
    dispatch({type: 'filter group cleared', payload: {group}})
  }

  const handleQuerySubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const payload = formData.get('q')
    if (typeof payload !== 'string') return
    dispatch({type: 'query changed', payload})
  }

  const toggleFilterBy: React.MouseEventHandler<HTMLHeadingElement> = event => {
    event.preventDefault()
    setFilterByModal(current => !current)
  }
  React.useEffect(() => {
    const newMatchedTypes = itemTypes?.filter(itemType =>
      activeTypes.includes(itemType.name as 'Text' | 'Literature'),
    )
    setMatchedTypes(newMatchedTypes as types.ItemType[])

    const newUniquePropNames = Array.from(
      new Set(
        newMatchedTypes?.flatMap(itemType =>
          itemType.features.map(feature =>
            feature.propName === 'academicLevel' ? 'level' : feature.propName,
          ),
        ),
      ),
    )
    setUniquePropNames(newUniquePropNames)
  }, [activeTypes, itemTypes, setSearchParams, state])

  return (
    <form onSubmit={handleQuerySubmit}>
      <div className="mt-8 mb-2 items-baseline  gap-16 md:-mt-4 md:flex">
        <h1 className="mb-4 w-52 shrink-0 text-xl">Browse all catalog</h1>
        <div className="flex w-full max-w-4xl items-center gap-2">
          <input
            defaultValue={state.q}
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            className="leading-2 block grow rounded-md border-2 border-slate-300 bg-transparent px-2 py-2 text-base outline-none placeholder:italic focus-visible:border-blue-400"
            autoFocus
            placeholder="Search by title, author, ISBN or Kel code"
            type="search"
            name="q"
          />
          <button
            className="shrink-0 rounded-full p-2 text-slate-400 outline-none hover:bg-blue-100 hover:text-blue-700 focus-visible:ring-2"
            type="submit">
            <MagnifyingGlassIcon className="w-6" aria-label="Search" />
          </button>
        </div>
      </div>

      <div className="flex items-start gap-10">
        <div className="max-w-4xl grow">
          <FilterChips onClick={handleToggleFilter} />

          <div className="flex items-baseline justify-between border-b pt-2 pb-2">
            {data ? (
              <p className="text-sm text-slate-500">
                {data.count} {data.count === 1 ? 'book' : 'books'}
              </p>
            ) : (
              <div className="inline-block h-4 w-20 animate-pulse self-center rounded-md bg-slate-200" />
            )}
            <SortBy
              className="hidden md:block"
              onChange={e =>
                dispatch({
                  type: 'sort changed',
                  payload: e.target.value as State['sort'],
                })
              }
              sort={state.sort}
            />
            <h2
              className="p-2 text-sm text-slate-500 md:hidden"
              onClick={toggleFilterBy}>
              <FunnelIcon className="mr-0.5 -ml-1 inline-block w-5 align-middle text-slate-400" />{' '}
              Filter by
            </h2>
          </div>

          {data && data.data.length > 0 && (
            <>
              <ul
                className={classNames(
                  isValidating && 'opacity-50',
                  'divide-y',
                )}>
                {data.data.map(item => {
                  return (
                    <li key={item.id} className="py-6 px-0">
                      <ListItem item={item} />
                    </li>
                  )
                })}
              </ul>
            </>
          )}

          {data && data.data.length === 0 && (
            <div className="mx-auto my-24 text-center">
              <HandThumbDownIcon className="inline-block w-12 text-slate-400" />
              <div className="mt-2 text-lg text-slate-500">
                Sorry, no results for your search
              </div>
            </div>
          )}

          {isSearching && <Spinner className="mx-auto mt-[calc(40vh-300px)]" />}

          {error && !data && (
            <div className="mx-auto my-24 text-center">
              <ExclamationCircleIcon className="inline-block w-12 text-red-400" />
              <div className="mt-2 text-lg text-slate-500">{error.message}</div>
            </div>
          )}
        </div>

        <div
          className={classNames(
            isSearching
              ? 'animate-pulse rounded-lg bg-slate-100'
              : 'rounded-md bg-slate-50',
            `${
              filterByModal ? 'w-60 opacity-100' : 'w-0 opacity-0'
            } fixed top-0 bottom-0 right-0 -order-1 -ml-4 mr-2 shrink-0 overflow-auto px-2 pt-3 pb-2 shadow-sm transition-width duration-500 md:relative md:w-60 md:opacity-100`,
          )}>
          {isSearching ? (
            <div className="min-h-[500px]" />
          ) : (
            <div className="flex h-8 items-baseline justify-between">
              <div className="flex flex-grow">
                <h2 className="p-2 text-sm text-slate-500">
                  <FunnelIcon className="mr-0.5 -ml-1 inline-block w-5 align-middle text-slate-400" />{' '}
                  Filter by
                </h2>
                <div
                  className="flex flex-grow justify-end md:hidden"
                  onClick={toggleFilterBy}>
                  <XCircleIcon className="mr-0.5 -ml-1 inline-block w-5 align-middle text-slate-400" />
                </div>
              </div>
              {!isSearching &&
                Object.values(state.filters).some(f => f.length > 0) && (
                  <button
                    className="rounded-md p-2 text-xs text-slate-500 underline decoration-slate-300 underline-offset-1 outline-none focus-visible:ring-2"
                    onClick={() => dispatch({type: 'all cleared'})}
                    type="button">
                    Clear all
                  </button>
                )}
            </div>
          )}

          <SortBy
            className="mt-3 ml-2 block md:hidden"
            onChange={e =>
              dispatch({
                type: 'sort changed',
                payload: e.target.value as State['sort'],
              })
            }
            sort={state.sort}
          />

          {data?.filters.map(filter => {
            switch (filter.type) {
              case 'array': {
                if (filter.name === 'type' || filter.name === 'component') {
                  return (
                    <ArrayFilterControl
                      className="mt-2"
                      key={filter.name}
                      title={filter.name}
                      urlParam={filter.name}
                      items={filter.values}
                      onToggle={handleToggleFilter}
                      onClear={handleClearGroup}
                    />
                  )
                } else if (matchedTypes && matchedTypes.length > 0) {
                  return uniquePropNames.includes(filter.name) ? (
                    <ArrayFilterControl
                      className="mt-2"
                      key={filter.name}
                      title={
                        filter.name === 'workload'
                          ? `${filter.name} (semanales)`
                          : filter.name === 'serie'
                          ? 'series'
                          : filter.name
                      }
                      urlParam={filter.name}
                      items={filter.values}
                      onToggle={handleToggleFilter}
                      onClear={handleClearGroup}
                    />
                  ) : (
                    <></>
                  )
                }

                return (
                  <ArrayFilterControl
                    className="mt-2"
                    key={filter.name}
                    title={
                      filter.name === 'workload'
                        ? `${filter.name} (semanales)`
                        : filter.name === 'serie'
                        ? 'series'
                        : filter.name
                    }
                    urlParam={filter.name}
                    items={filter.values}
                    onToggle={handleToggleFilter}
                    onClear={handleClearGroup}
                  />
                )
              }
              case 'boolean':
                return (
                  <BooleanFilterControl
                    className="mt-4"
                    label={
                      // Use a custom label for this filter
                      filter.name === 'isRecommended'
                        ? 'Recommended'
                        : filter.name
                    }
                    urlParam={filter.name}
                    onToggle={handleToggleFilter}
                    key={filter.name}
                  />
                )
              default:
                return null
            }
          })}
        </div>
      </div>
      <div className="mt-6 flex flex-wrap items-center justify-center gap-4">
        <Paginator
          currentPage={state.page}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </form>
  )
}

type Props = {
  sort:
    | 'edition|desc'
    | 'edition|asc'
    | 'price|desc'
    | 'price|asc'
    | 'relevance|desc'
    | 'relevance|asc'
  onChange: React.ChangeEventHandler<HTMLSelectElement>
  className: string
}

function SortBy({sort, onChange, className}: Props) {
  return (
    <label className={`text-sm text-slate-500 ${className}`}>
      Sort by
      <select
        value={sort}
        onChange={onChange}
        className="rounded-md py-1 font-semibold text-slate-500 outline-none ring-offset-1 focus-visible:ring-2 md:ml-2">
        <option value="edition|desc">Newest edition</option>
        <option value="edition|asc">Oldest edition</option>
        <option value="price|desc">Highest price</option>
        <option value="price|asc">Lowest price</option>
        <option value="relevance|desc">Most relevant</option>
        <option value="relevance|asc">Least relevant</option>
      </select>
    </label>
  )
}
