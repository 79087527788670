import {
  ExclamationCircleIcon,
  BuildingOfficeIcon,
  SparklesIcon,
  ArrowDownTrayIcon,
  LinkIcon,
  ArrowLeftIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/outline'
import {useNavigate, useParams} from 'react-router-dom'
import * as React from 'react'

import RelatedComponentsSection from '../components/RelatedComponentsSection'
import RelatedLevelsSection from '../components/RelatedLevelsSection'
import RelatedItemsSection from '../components/RelatedItemsSection'
import AddToListDialog from '../components/AddToListDialog'
import useInListsCount from '../hooks/useInListsCount'
import {Item} from '../types'
import Spinner from '../components/Spinner'
import useItem from '../hooks/useItem'
import ReadersComponentsSection from '../components/ReadersComponentsSection'
import useItemTypes from '../hooks/useItemTypes'
import useSiteConfiguration from '../hooks/useSiteConfiguration'

export default function ItemDetailPage() {
  const [showModal, setShowModal] = React.useState(false)
  const {id} = useParams<{id: Item['id']}>()
  if (!id) throw new Error('item id not present in URL')

  const relatedComponents = ["Student's", 'Workbook', "Teacher's"]

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('es-AR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value)
  }

  const {data, error} = useItem(id)

  const {data: itemTypes} = useItemTypes()

  const listsCount = useInListsCount(id)

  const [action, setAction] = React.useState<'manage-lists' | null>(null)

  const isLoading = !data && !error
  const navigate = useNavigate()

  const {getStockText} = useSiteConfiguration()

  const stock = getStockText(data?.stock)

  const copyText = (text: string | number) => {
    navigator.clipboard
      .writeText(text.toString())
      .then(() => {
        console.log('Text copied to clipboard')
        setShowModal(true)
        setTimeout(() => {
          setShowModal(false)
        }, 1100)
      })
      .catch(err => {
        console.error('Failed to copy text: ', err)
      })
  }

  const modalView = () => {
    return (
      <div className="absolute bottom-10 left-0 right-0 flex items-center justify-center">
        <div className=" rounded bg-blue-700  p-4">
          <p className=" text-center text-zinc-100">Copied!</p>
        </div>
      </div>
    )
  }
  return (
    <div>
      <button
        className="mb-4 flex cursor-pointer items-center text-blue-500 hover:underline"
        onClick={() => navigate(-1)}>
        <ArrowLeftIcon className="mr-2 h-3 w-4" /> Back
      </button>
      {data && (
        <>
          <div className="items-start gap-8 md:flex">
            <div className="relative w-1/4 max-w-[240px]">
              <div className="relative">
                <img
                  className="bg-slate-200 shadow-lg"
                  src={data.thumbnail || '/Image_not_available.png'}
                  alt=""
                />
                {data.isRecommended && (
                  <div className="absolute bottom-2 -right-3 rounded-sm rounded-tr-none bg-green-600 px-4 py-1 text-sm text-white shadow-lg [text-shadow:1px_1px_0_gray]">
                    Recommended
                    <svg
                      className="absolute bottom-[100%] right-0 fill-green-700"
                      viewBox="0 0 8 5"
                      height="8"
                      width="12">
                      <polygon points="0,0 8,5 0,5" />
                    </svg>
                  </div>
                )}
              </div>
            </div>

            <div className="grow">
              <div className="flex items-center gap-4">
                <h1 className="text-2xl md:text-3xl">{data.title}</h1>
                {data.isFeatured && (
                  <span className="mt-2 rounded-md border border-transparent text-sm text-amber-600">
                    <SparklesIcon className="relative -top-0.5 mr-0.5 inline-block w-6 align-middle text-amber-500" />{' '}
                    Featured
                  </span>
                )}
              </div>
              <p className="text-slate-500">by {data.author}</p>

              <div className="mt-3">
                <p className="text-sm text-slate-600">
                  <BuildingOfficeIcon className="relative -top-0.5 -ml-0.5 inline-block w-5 align-middle text-slate-400" />{' '}
                  {data.editorial}{' '}
                  <span className="mx-1 text-slate-400">•</span> {data.edition}{' '}
                  <span className="mx-1 text-slate-400">•</span>{' '}
                  <span className="font-semibold text-green-600">
                    {data.price ? `$ ${formatCurrency(data.price)}` : '$$$'}{' '}
                  </span>{' '}
                </p>
              </div>

              <div className="mt-3">
                {(data.labels || []).map(label => {
                  return (
                    <span
                      key={`label-${label}`}
                      className="relative -top-0.5 mr-1 rounded-md border border-orange-400 px-1 py-0.5 font-semibold uppercase text-orange-400 [font-size:11px] [letter-spacing:1px]">
                      {label}
                    </span>
                  )
                })}
                {stock && stock.text && (
                  <span
                    style={{
                      backgroundColor: stock.backgroundColor,
                      borderColor: stock.backgroundColor,
                      color: stock.textColor,
                    }}
                    className={`relative -top-0.5 mr-1 rounded-md border px-1 py-0.5 font-semibold uppercase [font-size:11px] [letter-spacing:1px]`}>
                    {stock.text}
                  </span>
                )}
              </div>

              <div className="mt-1">
                {relatedComponents.includes(data.component) ? (
                  <RelatedComponentsSection
                    currentComponent={data.component}
                    itemId={data.id}
                  />
                ) : (
                  <ReadersComponentsSection
                    currentComponent={data.component}
                    item={data}
                  />
                )}
              </div>

              <div className="mt-1">
                {relatedComponents.includes(data.component) ? (
                  <RelatedLevelsSection
                    currentLevel={data.cefrLevel}
                    itemId={data.id}
                  />
                ) : (
                  <RelatedLevelsSection
                    currentLevel={data.cefrLevel}
                    itemId={data.id}
                    uniqueLevel={true}
                  />
                )}
              </div>

              {typeof listsCount === 'number' && (
                <button
                  className="mt-8 flex items-baseline gap-2 whitespace-nowrap rounded-md border-2 border-transparent bg-blue-700 px-6 py-2 text-sm text-white outline-none ring-offset-2 hover:bg-blue-800 focus-visible:ring-2 disabled:cursor-not-allowed disabled:opacity-40 disabled:hover:bg-blue-700"
                  onClick={() => setAction('manage-lists')}>
                  {listsCount > 0
                    ? `Manage lists (${listsCount})`
                    : 'Add to list'}
                </button>
              )}
            </div>
          </div>

          <p className="mt-8 max-w-prose text-base">{data.description}</p>
          {itemTypes && itemTypes?.length > 0 && (
            <dl className="mt-8 grid gap-y-8 gap-x-20 text-xs md:grid-cols-[repeat(3,max-content)]">
              {itemTypes
                .find(i => i.name === data.type)
                ?.features.map((feature, index) => {
                  const value = data[feature.propName as keyof typeof data]
                  return (
                    <div
                      key={index}
                      className="border-l-2 border-blue-500 pl-3">
                      <dt className="text-slate-500">{feature.displayName}</dt>
                      <dd className="text-semibold text-lg text-slate-800">
                        {(typeof value === 'string' ||
                          typeof value === 'boolean') &&
                          value.toString()}
                      </dd>
                    </div>
                  )
                })}
            </dl>
          )}
          <dl className="mt-6 grid gap-6  md:grid-cols-[repeat(2,max-content)] md:gap-24">
            <div className="flex  gap-2">
              <dt className="text-md text-slate-500">ISBN</dt>
              <dd className="text-md text-slate-700">{data.isbn}</dd>
              <button
                className="flex cursor-pointer   "
                onClick={() => copyText(data.isbn)}>
                <DocumentDuplicateIcon className="mr-2 h-5 w-5" />
              </button>
            </div>
            <div className="flex gap-2">
              <dt className="text-md text-slate-500">KEL code</dt>
              <dd className="text-md text-slate-700">{data.kelCode}</dd>

              <button
                className="flex cursor-pointer  "
                onClick={() => copyText(data.kelCode)}>
                <DocumentDuplicateIcon className="mr-2 h-5 w-5" />
              </button>
            </div>
          </dl>
          {showModal && modalView()}
          {data.showroom.length > 0 && (
            <section className="mt-10">
              <h2 className="text-sm uppercase text-slate-500">LOOK INSIDE</h2>
              <ul className="mt-2 space-y-1">
                {data.showroom.map(material => {
                  return (
                    <li key={material.url}>
                      <a
                        className="text-sm text-blue-700 underline decoration-blue-200 underline-offset-2 hover:text-blue-800 hover:decoration-blue-400"
                        download={material.type === 'pdf'}
                        href={material.url}>
                        {material.type === 'website' ? (
                          <LinkIcon className="relative -top-0.5 mr-1.5 -ml-0.5 inline-block w-5 opacity-60" />
                        ) : (
                          <ArrowDownTrayIcon className="relative -top-0.5 mr-1.5 -ml-0.5 inline-block w-5 opacity-60" />
                        )}
                        {material.title}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </section>
          )}

          <div className="mt-10">
            <RelatedItemsSection itemId={data.id} title="Recommended" />
          </div>

          <AddToListDialog
            onSubmit={() => {}}
            onClose={() => setAction(null)}
            isOpen={action === 'manage-lists'}
            item={data}
          />
        </>
      )}

      {isLoading && <Spinner className="mx-auto mt-[calc(50vh-300px)]" />}

      {!data && error && (
        <div className="mx-auto my-24 text-center">
          <ExclamationCircleIcon className="inline-block w-12 text-red-400" />
          <div className="mt-2 text-slate-500">{error.message}</div>
        </div>
      )}
    </div>
  )
}
