import {
  ExclamationCircleIcon,
  HandThumbDownIcon,
} from '@heroicons/react/24/outline'
import {useSearchParams} from 'react-router-dom'
import * as React from 'react'

import {classNames} from '../utils'
import * as types from '../types'
import useForYou from '../hooks/useForYou'
import ListItem from '../components/ListItem'
import Spinner from '../components/Spinner'
import Paginator from '../components/Paginator'

type State = Pick<types.SearchParams, 'sort'> & {
  page: number
  itemsPerPage: number
}

type Action =
  | {type: 'sort changed'; payload: State['sort']}
  | {type: 'page changed'; payload: State['page']}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'sort changed': {
      return {...state, sort: action.payload}
    }
    case 'page changed': {
      return {...state, page: action.payload}
    }
  }
}

function init(currentParams?: URLSearchParams): State {
  const params = currentParams || new URLSearchParams()
  return {
    sort: params.get('sort') || 'edition|desc',
    page: parseInt(params.get('page') || '1', 10),
    itemsPerPage: parseInt(params.get('limit') || '10', 10),
  } as State
}

export default function SearchPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isPageChanged, setIsPageChanged] = React.useState(false)
  const [totalPages, setTotalPages] = React.useState<number>(0)
  const [state, dispatch] = React.useReducer(reducer, searchParams, init)

  React.useEffect(() => {
    const {page, itemsPerPage, ...other} = state
    const offset = isPageChanged
      ? (page - 1) * itemsPerPage
      : searchParams.get('offset')

    setSearchParams(
      {
        ...other,
        limit: itemsPerPage.toString(),
        offset: offset ? offset.toString() : '0',
        page: page.toString(),
      },
      {replace: true},
    )
  }, [setSearchParams, state, isPageChanged, searchParams]) // eslint-disable-next-line

  const {data, isValidating, error} = useForYou(state, {
    // handy for keep displaying the filters while executing a new search
    keepPreviousData: true,
  })

  React.useEffect(() => {
    setTotalPages(data ? Math.ceil(data.count / state.itemsPerPage) : 1)
  }, [data, state.itemsPerPage])

  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > totalPages) return
    dispatch({type: 'page changed', payload: newPage})
    setIsPageChanged(true)
  }

  const isSearching = !data && !error

  return (
    <form className="mt-12 md:mt-0">
      <h1 className="-mt-2 mb-3 text-xl">
        Based on your profile, these are our recommendations for you
      </h1>

      <div className="flex items-start gap-10">
        <div className="max-w-4xl grow">
          <div className="flex items-baseline justify-between border-b pt-4 pb-2">
            {data ? (
              <p className="text-sm text-slate-500">
                {data.count} {data.count === 1 ? 'book' : 'books'}
              </p>
            ) : (
              <div className="inline-block h-4 w-20 animate-pulse self-center rounded-md bg-slate-200" />
            )}

            <label className="text-sm text-slate-500">
              Sort by
              <select
                className="ml-2 rounded-md py-1 font-semibold text-slate-500 outline-none ring-offset-1 focus-visible:ring-2"
                onChange={e =>
                  dispatch({
                    type: 'sort changed',
                    payload: e.target.value as State['sort'],
                  })
                }
                value={state.sort}>
                <option value="edition|desc">Newest edition</option>
                <option value="edition|asc">Oldest edition</option>
                <option value="price|desc">Highest price</option>
                <option value="price|asc">Lowest price</option>
                <option value="relevance|desc">Most relevant</option>
                <option value="relevance|asc">Least relevant</option>
              </select>
            </label>
          </div>

          {!!data && !!data.filters && (
            <div className="mt-2 flex flex-col justify-evenly gap-2">
              {Object.keys(data.filters).map(key => {
                return (
                  <div
                    className="mt-2 flex flex-row justify-start gap-2"
                    // @ts-ignore
                    key={`${data.filters[key]}-key`}>
                    <p className="text-sm font-semibold text-slate-500">
                      {/* @ts-ignore */}
                      {key}:
                    </p>
                    <p className="text-sm text-slate-500">
                      {/* @ts-ignore */}
                      {data.filters[key]}
                    </p>
                  </div>
                )
              })}
            </div>
          )}

          {data && data.data.length > 0 && (
            <>
              <ul
                className={classNames(
                  isValidating && 'opacity-50',
                  'divide-y',
                )}>
                {data.data.map(item => {
                  return (
                    <li key={item.id} className="py-6 px-0">
                      <ListItem item={item} />
                    </li>
                  )
                })}
              </ul>
              <div className="mt-6 flex items-center justify-center gap-4">
                <Paginator
                  currentPage={state.page}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              </div>
            </>
          )}

          {data && data.data.length === 0 && (
            <div className="mx-auto my-24 text-center">
              <HandThumbDownIcon className="inline-block w-12 text-slate-400" />
              <div className="mt-2 text-lg text-slate-500">
                Sorry, no results for you yet
              </div>
            </div>
          )}

          {isSearching && <Spinner className="mx-auto mt-[calc(40vh-300px)]" />}

          {error && !data && (
            <div className="mx-auto my-24 text-center">
              <ExclamationCircleIcon className="inline-block w-12 text-red-400" />
              <div className="mt-2 text-lg text-slate-500">{error.message}</div>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}
